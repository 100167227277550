.ReactTable {
    border-radius: 10px 10px 0px 0px !important;
    border: none !important;
    text-align: center !important;
}

.ReactTable .rt-thead.-header {
    color: white;
    border-radius: 10px 10px 0px 0px !important;
    background-color: #0656a3;
}

.extension-number-table  .rt-thead .rt-th {
    overflow: inherit !important;
}

.extension-number-table .rt-thead,
.extension-number-table .rt-tbody,
.extension-number-table .pagination-bottom {
    width: 98.5%;
}

.ReactTable .rt-thead.-filters {
    background-color: white;
}

.ReactTable .rt-tr.-even {
    background-color: white;
}

.ReactTable .rt-tr.-odd {
    background-color: #eef3f9 !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: #e0e7ef !important;
}

.ReactTable .control-button {
    background-color: #808080;
    color: white !important;
    margin: 0px 2px 0px 2px;
}

.ReactTable .control-button:hover, .ReactTable .control-button:focus {
    background-color: #444;
    color: white;
    outline: none;
}

.ReactTable .control-button-disabled {
    background-color: #444444;
    color: #808080 !important;
    margin: 0px 2px 0px 2px;
}

.ReactTable .control-button-disabled:hover, .ReactTable .control-button-disabled:focus {
    background-color: #333333;
    color: #808080;
    outline: none;
}

.ReactTable .fa-icon {
    margin: 1px 4px 0px 7px;
    width: 17px;
    height: 17px;
}

.ReactTable .rt-table {
    z-index: 0 !important;
}

.ReactTable .rt-tbody {
    overflow : overlay !important;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ReactTable .row {
    margin-right : auto !important;
    margin-left : auto !important;
}

.info-icon {
    float: right;
}

.modal-info-icon {
    margin-left: 5px;
}

.call-analysis-modal-table .rt-tbody{
    overflow-x: hidden !important;
}

.predictive-call-table .rt-tbody{
    overflow-x: hidden !important;
}

.predictive-call-history-detail-table .rt-tbody{
    overflow-x: hidden !important;
}

.schedule-extension-table .rt-tbody{
    overflow-x: hidden !important;
}

.schedule_dispensing_table .rt-tbody{
    overflow-x: hidden !important;
}

.schedule-wait-call-infos-table .rt-tbody{
    overflow-x: hidden !important;
}

.schedule-over-wait-call-infos-table .rt-tbody{
    overflow-x: hidden !important;
}

.others-table {
    border-collapse: collapse;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ddd;
}

.others-table th,
.others-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.others-table th {
    background-color: #0656a3;
    color: white;
    width: 50%;
}

.others-table td {
    background-color: white;
}

.others-table tbody tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

.others-table tbody tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

.others-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.others-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.call-history-analysis-table{
    margin-bottom: 10px;
    height: 7rem;
}

@media screen and (max-width: 1500px) {
    .call-history-analysis-table{
        height: 9rem;
    }
}

.call-history-analysis-table .rt-td{
    background-color: white !important;
}