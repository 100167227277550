.history-detail-search-menu-row {
    width: 100%;
    margin: 0;
}

.history-detail-search-title-col {
    position: sticky;
    padding: 10px;
    top: 0;
    z-index: 1;
    background-color: #eef3f9;
}

.history-detail-search-menu-hr {
    width: 95%;
    border-top: 3px solid #999;
    margin-top: 80px;
}

.history-detail-search-colon {
    width: 2% !important;
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
    padding-top: 0.5em !important;
}

.history-detail-search-swung {
    width: 2% !important;
    margin-left: 0.4em !important;
    margin-right: 1.0em !important;
    padding-top: 0.5em !important;
}

.history-detail-search-condition-time-period {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0.5em !important;
}

.history-detail-search-condition-time-period .padding-right-0 .form-group .col-sm-12 {
    padding-left: 0;
    padding-right: 0;
}

.condition .form-group {
    margin-bottom: 0 !important;
}

.history-detail-search-button-size {
    width: 9em !important;
}

.history-detail-search-clear-button {
    margin-right: 0.5em !important;
    background-color: rgb(216, 216, 216);
    color: gray;
}

.history-detail-search-inline .col-sm-12 {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

.history-detail-search-padding-bottom-35 {
    padding-bottom: 3.5em !important;
}