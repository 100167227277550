.ColumnFixTable .rt-table .rt-thead .rt-tr .rt-resizable-header {
    border-radius: 10px 10px 0px 0px;
    background-color: #0656a3 !important;
}

.rthfc .rt-tbody {
    overflow :unset !important;
}

.rthfc .rt-thead.-headerGroups,
.rthfc .rt-thead.-header {
    z-index: 3;
    max-height: 30px;
}

.rthfc .rt-thead.-filters {
    z-index: 2;
}

.rt-table {
    max-height: 50em;
}

.rthfc .rt-th,
.rthfc .rt-td {
    background-color: #fff;
}

.rthfc .-headerGroups .rt-th {
    background-color: #f7f7f7;
}

.rthfc .rt-thead.-filters {
    background-color: white;
}

.rthfc .rt-tr.-even {
    background-color: white;
}

.rthfc.-striped .rt-tr.-odd .rt-td:not(.row_color_highlight) {
    background-color: #eef3f9;
}

.rthfc.-highlight .rt-tr:hover .rt-td:not(.row_color_highlight) {
    background-color: #e0e7ef;
}

.rthfc.-striped .row_color_highlight .rt-td {
    background-color: #fcf8c4 !important;
}

.rthfc.-highlight .row_color_highlight .rt-td {
    background-color: #fcf8c4 !important;
}

.rthfc.-striped .row-font-weight-bold .rt-td {
    font-weight: bold !important;
}

.rthfc.-highlight .row-font-weight-bold .rt-td {
    font-weight: bold !important;
}

.rthfc .rt-th.rthfc-th-fixed-left-last,
.rthfc .rt-th.rthfc-th-fixed-left-last,
.rthfc .rt-td.rthfc-td-fixed-left-last {
    border-right: solid 1px rgb(143, 143, 143) !important;
}

.rthfc .rt-th.rthfc-th-fixed-right-first
.rthfc .rt-th.rthfc-th-fixed-right-first,
.rthfc .rt-td.rthfc-td-fixed-right-first {
    border-left: solid 1px rgb(143, 143, 143) !important;
}

.rt-th .rthfc-th-fixed .rthfc-th-fixed-right 
.rthfc-th-fixed-right-first .rt-resizable-header {
    border-radius: 0px 0px 0px 0px !important;
}

.rthfc .rt-th.rthfc-th-fixed-left-last {
    border-right: solid 1px #ccc !important;
}

.rthfc .rt-th.rthfc-th-fixed-right-first {
    border-radius: 0px 10px 0px 0px !important;
    border-left: solid 1px #ccc !important;
}


.rthfc.-sp {
    border-left: solid 1px #ccc !important;
}

.rthfc.-sp .rt-tbody {
    overflow: visible;
    flex: 1 0 auto;
}

.rthfc.-sp .rt-thead {
    position: -webkit-sticky;
    position: sticky;
}

.rthfc.-sp .rt-thead.-headerGroups {
    border-bottom-color: #f2f2f2;
}

.rthfc.-sp .rt-tfoot {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    bottom: 0px;
}

.rthfc.-sp .rthfc-th-fixed,
.rthfc.-sp .rthfc-td-fixed {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
}

.rthfc.-sp .rthfc-th-fixed {
    z-index: 11;
}

.rthfc.-sp .rthfc-th-fixed-left {
    border-radius: 10px 0px 0px 0px !important;
}

.rthfc.-sp .rthfc-th-fixed-left,
.rthfc.-sp .rthfc-td-fixed-left {
    left: 0;
}

.rthfc.-sp .rthfc-th-fixed-right,
.rthfc.-sp .rthfc-td-fixed-right {
    border-radius: 0px 10px 0px 0px;
    right: 0px;
    flex: 90 0 auto;
    width: 90px;
}

.rthfc.-se .-header .rt-th.rthfc-th-fixed,
.rthfc.-se .-headerGroups .rt-th.rthfc-th-fixed,
.rthfc.-se .-filters .rt-th.rthfc-th-fixed,
.rthfc.-se .rt-td.rthfc-td-fixed {
    position: relative;
    z-Index: 1;
}

.rthfc.-se .-header .rt-th.rthfc-th-fixed {
    z-Index: 11;
}