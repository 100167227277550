.button-margin{
    margin-right: 5px;
}

.button-margin-right{
    margin-right: 23px;
}

.copy-btn {
    background-color: #0e7edb;
    color: white;
}

.copy-btn:hover {
    background-color: #0656a3;
    color: white;
}