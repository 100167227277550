

.modal-backdrop {
    background-color: #053e73;
}

.modal-content {
    border-radius: 10px;
}

.modal-header {
    border-radius: 10px 10px 0px 0px;
    background-color: #0656a3;
}

.modal-header .modal-title {
    font-weight: bold;
    color: white;
    margin-left: 3%;
}

.modal-header .x-execute {
    background-color: #0656a3;
    color: white;
    border: none;
    margin-right: 10px;
}

.modal-header .x-execute:focus {
    background-color: #0656a3;
    color: #ffffff80; 
    outline:none;
    box-shadow: none;
}

.modal-body {
    padding-top: 10px;
    line-height: 1;
    max-height: 70vh;
    overflow-y: auto;
}

.modal-body .panel {
    background-color: #eef3f9 !important;
    border: none;
    border-radius: 10px;
    margin-right: 15px;
    margin-left: 15px;
}

.modal-body .panel-heading {
    background-color: white;
    border: none;
    padding-left: 5px;
}

.modal-body .panel-heading .panel-title {
    font-weight: bold;
    color: black;
    opacity: 0.9;
}

.modal-body .panel-body {
    padding: 25px;
}

.modal-body .panel-body .control-label {
    text-align: left;
    font-size: 16px;
    padding-top: 0px;
    padding-bottom: 6px;
    margin-left: -2%;
}

.modal-body .panel-body .schedule-extension-label{
    margin-left: 0 !important;
    padding-bottom: 0 !important;
}

.modal-body .panel-body .schedule-extension-table{
    margin-bottom: 15px;
}

.modal-body .panel-body .schedule-wait-call-infos-label{
    margin-left: 0 !important;
    padding-bottom: 0 !important;
}

.modal-body .panel-body .schedule-wait-call-infos-table{
    margin-bottom: 15px;
}

.modal-body .panel-body .schedule-over-wait-call-infos-label{
    margin-left: 0 !important;
    padding-bottom: 0 !important;
}

.modal-body .panel-body .schedule-over-wait-call-infos-table{
    margin-bottom: 15px;
}

.modal-body .panel-body .control-button {
    margin: 0px 2px 0px 2px;
    border: 1px solid #ccc;
    color: #333
}

.modal-body .panel-body .control-button:hover, .modal-body .panel-body .control-button:focus {
    background-color: #444;
    color: white !important;
    outline: none;
}

.modal-body .panel-body .form-group .css-dvua67-singleValue {
    overflow: visible;
}

.control-button-modal {
    background-color: #808080;
    color: white;
    margin: 0px 2px 0px 2px;
}

.control-button-modal:hover, .control-button-modal:focus {
    background-color: #444;
    color: white;
    outline: none;
}

.modal-body .multiValueFormMinus:focus, .modal-body .multiValueFormPlus:focus, .multiValueFormToggle:focus{
    outline: none;
}

.modal-body .panel-body .has-error .control-label {
    color:#C7241C;
}

.modal-body .panel-body .has-error .form-control {
    border-color: #C7241C;
}

.modal-body .panel-body .has-error .css-bg1rzq-control {
    border-color: #C7241C;
}

@media screen and (min-width: 992px) {
    .modal-body .panel-body .control-label {
        text-indent: 10px;
    }
}

.modal-body .panel-body .checkbox {
    padding-top: 0px;
}

.modal-body .panel-body p.form-control-static {
    padding-top: 0px;
}

.modal-body .panel-body .fa-icon {
    width: 17px;
    height: 17px;
}

.modal-body .panel-body .fa-icon-static {
    margin-left: 10px;
}

.modal-footer .row .panel-button {
    margin-right: 5%;
}

.modal-footer .row .panel-button .cancel-execute,
.modal-footer .row .panel-button .cancel-locking
{
    margin-right: 15px;
    padding: 4px 20px;
}

.modal-footer .row .panel-button .close-execute{
    margin-left: 15px;
    padding: 4px 20px;
}

.modal-footer .row .panel-button .ok-execute,
.modal-footer .row .panel-button .confirmed-execute{
    padding: 4px 40px;
    background-color: #0e7edb;
    color: white;
}

.modal-footer .row .panel-button .save-btn {
    margin-left: 15px;
}

.modal-footer .row .panel-button .ok-execute:hover,
.modal-footer .row .panel-button .confirmed-execute:hover {
    padding: 4px 40px;
    background-color: #0656a3;
}

.modal-footer .row .panel-button .unconfirmed-execute{
    padding: 4px 40px;
    background-color: #919191;
    color: white;
}

.modal-footer .row .panel-button .unconfirmed-execute:hover {
    padding: 4px 40px;
    background-color: #696969;
}

.modal-footer .row .panel-button .ok-execute:focus,
.modal-footer .row .panel-button .cancel-execute:focus,
.modal-footer .row .panel-button .confirmed-execute:focus,
.modal-footer .row .panel-button .unconfirmed-execute:focus,
.modal-footer .row .panel-button .close-execute:focus
{
    outline: none;
}



/*** 画面固有設定 ***/
/** 着信グループ管理 **/
.modal-body .panel-body .groupType-icon {
    padding-top: 7px;
}

.modal-body .panel-body .groupType-text {
    padding-right: 0px;

}
/** **/
/** 内線番号管理 **/
.modal-body .panel-body .inboundType-icon {
    padding-top: 7px;
}

.modal-body .panel-body .inboundType-text {
    padding-right: 0px;
}

/** **/
/** 音声管理 **/
.fileRow{
    margin-top: 1%;
}

/** **/
/** 会社管理 **/
.company_checkbox{
    margin-left: 2%;
}


/** **/
/** ユーザー管理 **/
.modal-body .lastName-form {
    padding-left: 0px;
}

.modal-body .lastName-form .control-label {
    margin-left: -3%;
}

.modal-body .firstName-form {
    padding-left: 0px;
    margin-right: 2%;
}

.modal-body .firstName-form .control-label {
    margin-left: -3%;
}
/** **/
/** 顧客管理 **/
.modal-body .multiValueFormMinus{
    margin-bottom: 1.5% !important;
}
.modal-body .panel-body .form-group .multiValueFormValue{
    margin-bottom: 1.5% !important;
}
/** コール結果管理 **/
.modal-body .multi-value-form-dual-1{
    width: 35%;
}
.modal-body .multi-value-form-dual-2{
    width: 40%;
}
.modal-body .multi-value-form-dual-order{
    padding-left: 0px;
    width: 13%;
}

/** **/
/* 営業時間管理 */
.modal-body .calendar-Btn {
    padding: 0px;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: #1e72df;
    font-size: 15px;
}
.modal-body #update_Button{
    border: 1px solid #ccc;
}
.modal-body #update_Button:hover{
    background-color: #bdbdbd;
}

.modal-body .calendar-Btn:hover {
    padding: 0px !important;
    border: none !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    color:  #23527c !important;
}
.modal-body .calendar-DataSettingLabel {
    text-indent: 10px;
    margin-bottom: 7px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    display: inline-block;
}
.modal-body .calendar-UpdateRow {
    margin-bottom: 10px;
}

.modal-body .calendar-IreBtnAndLabel {
    padding-top: 20px;
}
.modal-body .calendar-IreBtn,.calendar-UpdateBtn {
    display: block;
    margin: 0 0 3px auto;
    border: 1px solid transparent !important;
    border-color: #ccc !important;
    padding: 7px 14px 7px 14px;
}

.modal-body .irr-btn-group{
    display: block;
    right: 0;
    bottom: -25px;
    position:absolute;
}
.modal-body .calendar-IreBtn:hover,.calendar-UpdateBtn:hover {
    border-color: #e6e6e6 !important;
    background-color: #bdbdbd !important;
}

.modal-body .rt-td{
    padding-top: 3%;
}

.download-history-modal .rt-td {
    padding: 7px 5px !important;
}

.modal-body .schedule-extension-table .rt-td{
    padding-top: 3%;
    padding-bottom: 3%;
    line-height: 20px;
}

.modal-body .schedule-wait-call-infos-table .rt-td{
    padding-top: 3%;
    padding-bottom: 3%;
    line-height: 20px;
}

.modal-body .schedule-wait-call-infos-table .rt-th{
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-body .schedule-over-wait-call-infos-table .rt-td{
    padding-top: 3%;
    padding-bottom: 3%;
    line-height: 20px;
}

.modal-body .rt-tbody{
    border-left: 1px solid #cccccc60;
    border-right: 1px solid #cccccc60;
    border-bottom: 1px solid #cccccc60;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.modal-body .calendar-SettingForm {
    margin: 0 0 0 auto;
}

.modal-body .calendar-Modal_TimeCss {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 10%;
}
.modal-body .calendar-Modal_CalendarDraw
{
    display: inline-block;
    width: 154px;
    height: 26px;
}
.set_audio_button {
    margin-top: 1.2em;
    font-size: 20px !important;
}
.set_audio_button_business_calendar {
    margin-top: 0.2em;
    font-size: 20px !important;
}

.preview_add{
    color: #C7241C;
}

.preview_message{
    font-weight: bold;
}

.preview_time{
    margin-top: 1%;
}

.spanData{
    margin-top: 0.5%;
    display: block;
}

.exist_irr{
    margin-left: 5%;
}

.user-belonged{
    color: #C7241C;
    font-weight: bold;
    margin-left: 27%;
}

/* 権限管理 */
.modal-body .permission-DataSettingLabel {
    text-indent: 10px;
    margin-bottom: 7px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    display: inline-block;
}

.asc-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
    z-index: 1;
}

.asc-block-ui .asc-block-icon-box {
    position: absolute;
    top: 40%;
    bottom: 55%;
    right: 60%;
    left: 50%;
}

.asc-block-ui .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #FFFFFF;
}
.set_control_parameter {
    margin-top: 0.6em;
}

.over-width-modal {
    width: 60% !important;
    max-width: none !important;
}

.non-scroll-bar .rt-tr-group{
    overflow-x: hidden;
}

.checkbox-disabled {
    display: block !important;
}

.omniphone_login_status_message {
    margin-left: 0.6em;
    margin-top: 0.5em;
    color: #c7241c;
}

.list-message{
    margin-bottom: 1%;
    color: #c7241c;
}

.omniphone-version-not-found-message {
    color: #999;
}

.signin-message{
    color: #555555;
}

.password-reset-flag{
    margin-bottom: 2% !important;
}

.voice-alert, .comment-alert{
    margin: 1.5%;
    margin-bottom: 0;
}

.voice-alert{
    position: sticky;
    top: 5%;
    z-index: 1;
}

.message-div {
    padding: 1% 1.5% 1% 1.5%;
}

.message-div.right {
    display: block;
    float: right;
    width: 100%;
    margin-bottom: 1%;
}

.message-box {
    display: inline-block;
    border-radius: 5px;
    padding: 1.5%;
    padding-left: 2%;
    padding-right: 2%;
    min-width: 50%;
    max-width: 70%;
    background-color: #fff;
    box-shadow: 3px 3px 0px 0px #ccc;
}

.current-message-box-left {
    box-shadow: 6px 6px 0px 0px #ccc;
    background-color: #F0FFFF !important;
}

.current-message-box-right {
    box-shadow: 6px 6px 0px 0px #ccc;
    background-color: #FFF0F5 !important;
}

.message-box.right{
    margin-right: 1.5%;
    background-color: #eee;
    float: right;
}

.history-analysis.message-box.left{
    background-color: #D6FFEF;
}

.history-analysis.message-box.right{
    background-color: #D6EDFF;
}

.history-analysis.current-message-box-left {
    box-shadow: 6px 6px 0px 0px #ccc;
    background-color: #ADFFDD !important;
}

.history-analysis.current-message-box-right {
    box-shadow: 6px 6px 0px 0px #ccc;
    background-color: #ADDCFF !important;
}

.message-box .user_name {
    margin-left: 0.8vw;
    font-weight: bold;
}

.message-box .comment-data, .message-box .voice-data{
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
}

.message-box .comment-data-edit, .message-box .voice-data-edit{
    font-size: 1em;
    margin-top: 1%;
    margin-bottom: 1%;
}

.message-box .comment-edit-btn{
    background-color: #0e7edb;
    color: white;
}

.message-box .comment-edit-btn:hover, .message-box .comment-edit-btn:focus {
    background-color: #0656a3 !important;
    color: white !important;
    outline:none !important;
    box-shadow: none !important;
}

.message-box .date-time, .message-box .data-updated {
    margin-left: 5%;
    color: #999;
    font-size: 0.9em;
}

.message-box .voice-time{
    margin-left: 2%;
    color: #999;
    font-size: 0.9em;
}

.message-box .voice-time.right{
    color: #c7241c;
}

.message-box .voice-time.left{
    color: #0e7edb;
}

.history-analysis.message-box .voice-time.right{
    color: #0074CC !important;
    margin-left: 5%;
    font-weight: bold;
    font-size: 0.8em;
}

.history-analysis.message-box .voice-time.left{
    color: #00A35F !important;
    margin-left: 5%;
    font-weight: bold;
    font-size: 0.8em;
}

.analysis-btn-group {
    float: right;
    margin-right: 0.8vw;
}

.analysis-btn-right {
    float: right;
    margin-right: 2% !important;
}

.pannel-btn-right {
    float: right;
    margin-right: -1.5% !important;
}

.comment-input{
    margin-left: 1.5%;
    width: 90%;
    display: inline;
    margin-bottom: 1.5%;
}

.comment-insert-btn {
    margin-top: -9.6%;
    margin-left: 0.5%;
    height: 56px;
    display: inline;
    background-color: #0e7edb;
    color: white;
}

.comment-insert-btn:hover, .comment-insert-btn:focus {
    background-color: #0656a3 !important;
    color: white !important;
    outline:none !important;
    box-shadow: none !important;
}

.voice-text-panel{
    scroll-behavior: smooth;
    height: 35vh;
    overflow-y: auto;
}
.voice-text-panel.pannel-hidden{
    overflow-y: hidden;
}

.history-analysis-panel.voice-text-panel{
    height: 765px !important;
}

.searched-keyword{
    background-color: rgba(255, 230, 0, 0.5);
}

.analysis-audio-control{
    position: sticky;
    z-index: 1;
    top: 27vh;
}

.analysis-audio-control-footer{
    padding: 5px;
    background-color: #ffffff7a;
    border: none;
}

.audio-player{
    margin-top: 0.7%;
    width:98%;
    height: 25px;
}

.voice-last-index{
    margin-bottom: 8%;
}

.no-comment-message, .no-voice-message{
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.audio-player::-webkit-media-controls-panel{
    background-color: white;
}

.voice-using{
    color: #C7241C;
    font-weight: bold;
    margin-top: 0.5%;
}

.voice-using-list{
    color: #C7241C;
    margin-top: 0.5%;
}

.schedule-validation{
    color: #C7241C;
    margin-bottom: 1%;
}

.autocall-time-select{
    margin-top: 0.8em;
    padding-left: 0.2em;
}

.company-using{
    color: #C7241C;
    font-weight: bold;
    margin-top: 0.5%;
}

.company-using-list{
    color: #C7241C;
    margin-top: 0.5%;
}
/***************/
/*** ***/
