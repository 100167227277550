.Header {
    margin: 0px;
    border: none !important;
    border-radius: 0px !important;
    background-color: white;
    text-align: center;
}

.Header .navbar-brand {
    color: white;
    font-weight: bold;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.Header-Bookmark-Icon button {
    width: 40px;
    height: 40px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 20px;
    border: none;
    background-color: white;
}

.Header-User-Icon button {
    width: 40px;
    height: 40px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 14px;
    border-radius: 50%;
    background-color: #cecece;
    color: white;
}

.Header-notification-icon button {
    width: 40px;
    height: 40px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 20px;
    border: none;
    background-color: white;
}

.popover-notification-title {
    display: flex;
    justify-content: space-between;
}

.popover-notification-title button {
    width: 40px;
    height: 40px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 20px;
    border: none;
    background-color: #F7F7F7;
}

.Header-notification-icon button:hover, .Header-notification-icon button:focus{
    background-color: #e4e3e3 !important;
    outline: none !important
}

.Header-User-Icon button:hover, .Header-User-Icon button:focus{
    background-color: #808080 !important;
    color: white !important;
    outline: none !important
}

.Header-User-Icon button span {
    margin-top: 0.12em;
    font-size: 1em;
}

.popover {
    max-width: 100%;
}

.popover-title {
    text-align: center;
    font-weight: bold;
}

.logout-button {
    text-align: center;
}

.change-password-button {
    text-align: center;
    margin-right: 10px;
}

.disabled {
    display: none;
}

.Header-User-Div {
    position: absolute;
}

.user-info-footer {
    text-align: center;
}

.left {
    text-align: left;
}

.rigth {
    text-align: right;
}

.padding-top-08 {
    padding-top: 0.8em;
}