.multiSelectPanel {
    height: auto;
    max-height: 23em;
    overflow-y: auto;
    border-radius: 4px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-right: -2.5% !important;
}

.multiSelectAllButton {
    background-color: transparent !important;
    margin-left: -3% !important;
}

.multiSelectAllButton .allSelect:focus, .allSelect.click {
    outline: none;
}

.multiSelectAllButton .allReset:focus, .allReset.click {
    outline: none;
}

@media screen and (min-width: 992px) {
    .multiSelectAllButton .btn {
        display: block;
    }

    .multiSelectAllButton .allSelect {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 991px) {
    .multiSelectSearchForm {
        margin-top: 10px
    }
    
    .multiSelectAllButton .allSelect {
        margin-right: 10px;
    }
}

/*.list-group-item:hover, .list-group-item:active, .list-group-item.active, .list-group-item.active:focus {*/
    /*background-color: #e6e6e6 !important;*/
    /*color: black !important;*/
    /*outline: 0;*/
/*}*/
/*
    ホバー状態のみ色を変化する場合、アクティブ状態の項目をホバーした際の色を追加で設定しないと違和感あるのと
    .list-group-item:hoverのみ分離させて色変えるとアクティブから通常状態に戻した項目がホバー効かなくなる
    ※最後にアクティブから通常に戻したものが該当。
*/
.list-group-item:hover, .list-group-item:active, .list-group-item.active, .list-group-item.active:focus {
    z-index: 2;
    color: #fff !important;
    background-color: #8f9ba6 !important;
    border-color: #8f9ba6 !important;
}

.list-group-item:focus {
    background-color: white !important;
    color: #555 !important;
    outline: none !important;
    border-color: white !important;
}