.App {
    font-family: Meiryo, Meiryo-Italic, MeiryoUI, MeiryoUI-Italic;
    height: 100%;
}

.App .container {
    width: auto;
    height: 100%;
    padding: 0px;
}

.asc-display-none {
    display: none;
}

.asc-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.asc-block-ui .asc-block-icon-box {
    position: absolute;
    top: 40%;
    bottom: 55%;
    right: 60%;
    left: 50%;
}

.asc-block-ui .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #FFFFFF;
}

/* .app-auth-check .spinning.glyphicon {
    animation: spin 1s infinite linear;
} */

.bgGray {
    background-color: #8c8c8c;
}

.bgRed {
    background-color: red;
}

.colorWhite {
    color: white;
}

.colorRed {
    color: red;
}

.signIn-logo-overview {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: skyblue;
    /* BtoC適用
     background-position: 90% 90%; */ 
    /* BtoB適用 */
    background-position: 50% 30%;
}

.signIn-form-overview {
    height: 100%;
    position: relative;
    background-color: #F2F4F4;
}

.img-logo {
    width: 80%;
    margin: 0 auto;
    width: 16em;
    height: 10%;
    max-width: 400px;
}

.git-version {
    position: fixed;
    bottom: 0;
    left: 0;
    color: white;
}

.signIn-logo-string {
    /* font-family: Georgia; */
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #FFFFFF;
    font-size: 7em;
    text-shadow: 6px 3px 3px black;
    /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
    opacity: 0.8;
    /* font-style: oblique; */
    font-weight: bolder;
}

.table-button {
    margin: 0px 10px 10px 0px;
    border-radius: 10px;
    padding: 5px 15px;
    font-size: 14px;
    color: white;
    background-color: #272d36;
}
.table-button:hover, .table-button:focus, .table-button:active, .table-button.click {
    background-color: black !important;
    color: #ffffff95 !important;
    outline: none !important;
}

#callhistory-download{
    margin-top: 1% !important;
}


@media screen and (max-width: 1024px) {
    .signIn-logo-string {
        font-size: 5em;
    }
    
    .seatview-form{
        width: 100% !important;
        display: block;
    }

    .callmonitor-form{
        width: 100% !important;
        display: block;
    }

}

.header-app {
    padding: 0px;
}

@media screen and (max-width: 767px) {
    .header-nav {
        background-color: #0656a3;
        padding: 0px;
    }
}

.sidebar-app {
    background-color: #0656a3;
    padding: 0px;
    overflow-y: auto;
}

.sidebar-app button {
    background-color: #0656a3;
}

.sidebar-app button:hover, .sidebar-app button:focus, .sidebar-app button:active, .sidebar-app button.cuscon-active,
.header-nav .sidebar-child:hover, .header-nav .sidebar-child:focus, .header-nav .sidebar-child:active, .header-nav .sidebar-child.cuscon-active {
    background-color: #053e73 !important;
    color: white !important;
    outline: none !important;
}

.routes-app {
    overflow-y: auto;
    /* padding: 3rem 4rem; */
    padding: 20px 30px;
    background-color: #F2F2F2;
}

.hover-black:hover {
    cursor: pointer;
    color: black !important;
}

.margin-right-02 {
    margin-right: 0.2em !important;
}

.margin-right-05 {
    margin-right: 0.5em !important;
}

.margin-right-1 {
    margin-right: 1em !important;
}

.margin-left--08 {
    margin-left: -0.8em !important;
}

.margin-right-15 {
    margin-right: 1.5em !important;
}

.margin-left-02 {
    margin-left: 0.2em !important;
}

.margin-left-05 {
    margin-left: 0.5em !important;
}

.margin-left-1 {
    margin-left: 1em !important;
}

.margin-left-3 {
    margin-left: 3em !important;
}

.margin-top--05 {
    margin-top: -0.5em !important;
}

.margin-top-1 {
    margin-top: 1em !important;
}

.margin-top-2 {
    margin-top: 2em !important;
}

.margin-top-01 {
    margin-top: 0.1em !important;
}

.margin-top-03 {
    margin-top: 0.3em !important;
}

.margin-top-05 {
    margin-top: 0.5em !important;
}

.margin-top-06{
    margin-top: 0.6em !important;
}

.margin-top-07 {
    margin-top: 0.7em !important;
}

.margin-top-08 {
    margin-top: 0.8em !important;
}

.margin-top-10 {
    margin-top: 1em !important;
}

.margin-top-12 {
    margin-top: 1.2em !important;
}

.margin-top-15 {
    margin-top: 1.5em !important;
}

.margin-top-40 {
    margin-top: 4em !important;
}

.margin-top-517 {
    margin-top: 5.17em !important;
}

.margin-bottom-0{
    margin-bottom: 0em !important;
}

.margin-bottom-05{
    margin-bottom: 0.5em !important;
}

.margin-bottom-07{
    margin-bottom: 0.7em !important;
}

.margin-bottom-08{
    margin-bottom: 0.8em !important;
}

.margin-bottom-20{
    margin-bottom: 2em !important;
}

.margin-left-auto{
    margin-left: auto;
}

.margin-left-15{
    margin-left: 1.5em !important;
}

.margin-0 {
    margin: 0 !important;
}

.padding-0 {
    padding: 0 !important;
}

.padding-right-0 {
    padding-right: 0 !important;
}

.padding-right-10 {
    padding-right: 10px !important;
}

.padding-left-0 {
    padding-left: 0 !important;
}

.padding-right-3{
    padding-right: 3px !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-left-15 {
    padding-left: 15px !important;
}

.padding-left-20 {
    padding-left: 20px !important;
}

.padding-left-30 {
    padding-left: 30px !important;
}

.padding-left-1em {
    padding-left: 1em !important;
}

.padding-top-03 {
    padding-top: 0.3em !important;
}

.padding-top-05 {
    padding-top: 0.5em !important;
}

.padding-top-07 {
    padding-top: 0.7em !important;
}

.padding-top-15 {
    padding-top: 1.5em !important;
}

.padding-top-16 {
    padding-top: 1.6em !important;
}

.padding-bottom-0 {
    padding-bottom: 0em !important;
}

.padding-bottom-03 {
    padding-bottom: 0.3em !important;
}

.padding-bottom-05 {
    padding-bottom: 0.5em !important;
}

.padding-bottom-07 {
    padding-bottom: 0.7em !important;
}

.z-index-0 {
    z-index: 0;
}

.z-index-1500 {
    z-index: 1500;
}

.icon-omni-contact {
    padding-right: 0.8em !important;
}

.font-color-grey {
    color: rgb(131, 131, 131);
}

.font-weight-bold {
    font-weight: bold;
}

.white-space-normal {
    white-space: normal !important;
}

.header-nav-item {
    background-color: black;
}
.result-count{
    color: #0656a3;
    text-align: right;
}

.result-count-limit{
    color: #C7241C;
    text-align: right;
    margin-right: 0.2em;
}

.search-message{
    color: #C7241C;
}

.call-analysis-label{
    margin-right: 4%;
}

.call-analysis-interval{
    display: flex !important;
    justify-content: flex-end !important; 
}

@media screen and (max-width: 1300px) {
    .call-analysis-interval{
        width: 21% !important;
        display: flex !important;
        justify-content: flex-end !important;
    }
}

@media screen and (min-width: 1500px) {
    .call-analysis-button-group{
        width: 16.7%;
        padding-right: 0;
    }
}

@media screen and (max-width: 1500px) {
    .call-analysis-button-group{
        width: auto;
        padding-right: 0;
    }
}

.call-analysis-button{
    margin-left: -1px;
    width: 67.6px;
}

@media screen and (max-width: 1500px) {
    .analysis-label.col-lg-1{
        width: 9.5% !important;
    }
}

@media screen and (max-width: 1310px) {
    .analysis-label.col-lg-1{
        width: 10% !important;
    }
}

.search-date{
    width: 35% !important;
}

.analysis-date-picker > div.react-datepicker-wrapper{
    width: 40% !important;
}

@media screen and (min-width: 1605px) {
.analysis-btn-col.col-lg-3 {
    width: 16.66666667% !important;
}
}
.analysis-btn :hover, .analysis-btn :focus{
    background-color: #337ab7;
    color: white;
}

.analysis-btn-focus{
    background-color: #337ab7;
    color: white;
}

.seatview-form{
    width: 25%;
    display: inline-block;
}

.seatview-btn{
    display: inline-block;
    margin-top: -1.6% !important;
}

.seatview-table, .seatview-table td{
    border: 2px solid #888;
    background-color: white;
}

.seatview-width-1{
    width:19.5%;
}

.seatview-panel-text{
    font-weight: bold;
    font-size: 0.85em;
    margin-top: -3px;
    margin-left: -3px;
}

.auto-update-btn {
    margin-left: -25px;
    margin-top: 4px;
}

.seatview-panel-data{
    font-weight: 900;
    font-size: 1.5em;
    color: #444;
    text-align: center;
}

.seatview-panel-time{
    font-weight: 900;
    font-size: 2em;
    color: #444;
    text-align: center;
    margin-top: 20%;
}

.status-answered{
    background-color: #B7D93D;
}

.status-end{
    background-color: #F26D6D;
}

.status-calling{
    background-color: #81DDF8;
}

.status-monitor, .status-wisper{
    background-color: #a995ff;
}

.status-null{
    background-color: #A6A6A6;
}

.status-common{
    font-weight: bold;
    font-size: 0.9em;
    border-radius: 15px;
    padding: 2px;
}

.full-screen-mode{
    padding: 20px;
    background-color: #F2F2F2;
}

@media screen and (max-width: 1366px) {
    .seatview-table-col{
        overflow-x: auto;
        width: 1360px;
    } 

    .callmonitor-table-col {
        overflow-x: auto;
        width: 100%;
        z-index: 0;
    }
}

.select-width {
    min-width: 200px;
}

.container-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.modal-checked-message{
    margin-top: 10px;
    font-size: small;
}

.csvImportMsg{
    margin-top: 10px;
}

a.csv-format-download-link{
    display: block;
    margin: 25px 0px 10px;
}

.ok-button-color{
    color: green;
}

.remove-button-color{
    background-color: #eee;
}

.default-button {
    padding: 4px 40px;
}

.ok-execute-btn {
    padding: 4px 40px;
    background-color: #0e7edb;
}

.ok-execute-btn:hover {
    background-color: #0656a3;
}

.label-color-333333 {
    color: #333333;
}

.extension-date-picker{
    width: 100%;
}

.fa-16 {
    font-size: 1.6em;
}

.fa-11 {
    font-size: 1.1em;
}

.fa-12 {
    font-size: 1.2em;
}

.fa-09 {
    font-size: 0.9em;
}

::placeholder {
    color: #999;
    opacity: 1;
}

.auto-call-select {
    width: 20em;
    height: 10em;
    cursor: pointer;
    cursor: hand;
    margin: 10px;
}

.body-auto-call-select {
    cursor: pointer;
    cursor: hand;
}

.summary-select {
    width: 20em;
    height: 10em;
    cursor: pointer;
    cursor: hand;
    margin: 10px;
}

.body-summary-select {
    cursor: pointer;
    cursor: hand;
}

.summary-checkbox {
    width: 10%;
    float: left;
}

.time-span-char{
    width: 1%;
    float: left;
}

a.csv-download-link-format{
    color: white;
    text-decoration: none;
}

.dropdown-search-clear-icon{
    display:none;
}

.text-align{
    text-align: right !important;
}

.text-align-left{
    text-align: left !important;
}

.text-align-center{
    text-align: center !important;
}

.flex-style{
    display: flex;
}

.flex-direction-row{
    display: flex;
    flex-direction: row;
}

.flex-direction-column{
    display: flex;
    flex-direction: column;
}

.wait-statuses-dropdown-menu {
    width: 650%;
}

.callmonitor-form{
    width: 25%;
}

.callmonitor-text{
    word-break: keep-all;
    text-align: right;
}

.callmonitor-setting-text{
    text-align: center;
}

.callmonitor-dropdown-menu {
    width: 600%;
}

.externalNumber-dropdown-menu {
    width: 250%;
}

.user-csv-export-dropdown-menu {
    width: 250%;
}

.callmonitor-inbound-alert-text {
    color: red;
}
.callmonitor-company-select-area {
    z-index: 4;
}
.callmonitor-department-select-area {
    z-index: 3;
}
.callmonitor-floor-select-area {
    z-index: 2;
}
.callmonitor-external-select-area {
    z-index: 2;
}
.cm-status-common{
    color: white;
    font-weight: 500;
    font-size: 0.9em;
    border-radius: 5px;
    padding: 5px;
}
.cm-status-login {
    background-color: #778c77;
}
.cm-status-ready {
    background-color: #80ab49;
}
.cm-status-calling {
    background-color: #20a675;
}
.cm-status-work {
    background-color: #ebb74a;
}
.cm-status-rest {
    background-color: #ac3c4c;
}
.script-audio-synthetic-select{
    width: 75%;
    float: left;
    margin-right: 10px;
}

.callmonitoring-select {
    width: 20em;
    height: 10em;
    cursor: pointer;
    cursor: hand;
    margin: 10px;
}

.body-callmonitoring-select {
    cursor: pointer;
    cursor: hand;
}

.body-waitstatuses-title {
    margin-right: -30px;
}

.body-waitstatuses-body {
    margin-right: -15px;
}

.ms-auto {
    margin-left: auto !important;
}

.float-style {
    float: right;
}

.width-max-content {
    width: max-content
}

.script-setting-msg{
    font-weight: bold;
    font-size: 0.8em;
    margin-bottom: 1em;
}

.history-analysis-label {
    font-weight: bold;
}

.history-analysis-btn-grp {
    margin-top: 5px;
}

.history-analysis-btn {
    padding: 3px 6px;
}

.history-analysis-panel {
    background-color: white;
}
.search-menu-div {
    position: relative;
    z-index: 1;
}

.top-label {
    margin-left: -0.5%;
    margin-top: 0.3%;
    margin-bottom: 0.3%;
}

.search-menu-button {
    position: fixed;
    right: 0;
    top: 50%;
    width: 30px;
    height: 70px;
    transition: all 0.5s ease !important;
    z-index: 1;
    border-width: 2px;
    border-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.search-menu-button.open {
    transform: translateX(-400px);
}

.search-menu-button.open-compare {
    transform: translateX(-800px);
}

.predictive-call-search-menu-button {
    position: fixed;
    right: 0;
    top: 45%;
    width: 30px;
    height: 70px;
    transition: all 0.5s ease !important;
    z-index: 1;
    border-width: 2px;
    border-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.predictive-call-search-menu-button.open {
    transform: translateX(-450px);
}

.call-chart-button button {
    border: none;
    background-color: transparent;
}

.call-chart-button :hover, .call-chart-button :focus{
    font-weight: bold;
    color: black;
    border-bottom: 3px solid gray !important;
}

.call-chart-button-focus{
    font-weight: bold;
    color: black;
    border-bottom: 3px solid gray !important;
}

.call-chart-button * :hover {
    border: none !important;
}

.call-chart-button svg {
    margin-left: 0.3em;
    border: none !important;
}

.line-chart-data {
    display: flex;
    justify-content: space-between;
}

.chart-title {
    font-size: 1.1em !important;
    font-weight: bold !important;
    margin-bottom: 1em !important;
}

.font-color-0080FF {
    color: #0080FF;
}

.font-color-FFB366 {
    color: #FFB366;
}

.font-color-00B9AB {
    color: #00B9AB;
}

.font-color-E01D5A {
    color: #E01D5A;
}

.font-color-black {
    color: black;
}

.common-line-chart {
    background-color: white !important;
    border: 2px solid #E6E6E6;
    border-radius: 4px
}

.common-line-chart-title {
    display: flex;
    align-items: center;
    padding-top: 1em;
}

.score span{
    font-size: large !important;
}

.common-line-chart-title span {
    font-size: medium;
    font-weight: bold;
}

.common-line-chart-title svg {
    margin-left: 0.3em;
}

.overview-table {
    background-color: white;
}

.overview-table tr td span:first-child {
    margin-left: 0.3em;
    float: left;
}

.overview-table tr td span:first-child svg {
    margin-left: 0.3em;
}

.overview-table tr td span:nth-child(2) {
    margin-right: 0.5em;
    float: right;
}

.compare-table {
    background-color: white;
}

.compare-table tr td span:first-child {
    margin-left: 0.3em;
    display: inline-block;
}

.compare-table tr td span:first-child svg {
    margin-left: 0.3em;
}

.compare-table tr td:nth-child(2) {
    text-align: center;
}

.compare-table tr td:nth-child(3) {
    text-align: center;
}

.analysis-summary-csv-download-button {
    background-color: #D8D8D8;
    color: #6E6E6E;
}

.total-call-tooltip {
    background-color: black;
    color: white;
    font-size: 11px;
    width: 150px;
    height: min-content;
    text-align: center;
}

.heatmap-tooltip {
    font-size: 14px;
    color: black;
    width: min-content;
    height: min-content;
    text-align: left;
}

.heatmap-tooltip div {
    padding: 0.3em 0.4em;
}

.common-line-chart-tooltip {
    font-size: 12px;
    width: min-content;
    height: min-content;
    text-align: left;
}

.common-line-chart-tooltip-date {
    color: gray !important;
    padding: 0.4em 0.6em 0.2em 0.6em;
}

.common-line-chart-tooltip-value {
    color: #5882FA !important;
    padding: 0.2em 0.6em 0.4em 0.6em;
}

.common-line-chart-tooltip-padding {
    padding: 0.2em 0.6em 0.4em 0.6em;
}

.common-line-chart-data-label {
    color: #5882FA !important;
    font-weight: bold;
}

.x-large-font-size {
    font-size: x-large;
}

.medium-font-size {
    font-size: medium;
}

.custom .tooltip-inner {
    text-align: left !important;
    max-width: 450px !important;
}

.speech-bubble-scheduled-inbound-group .tooltip-inner {
    max-width: 1000px !important;
}

#scheduled-inbound-group-infos-table tr td{
    border: 1px solid white;
    padding: 0 3px;
}

#scheduled-inbound-group-infos-group-name-table {
    width: 100%;
}

#scheduled-inbound-group-infos-group-name-table tr td{
    border: none;
}

.scheduled-inbound-group-infos-table tr td{
    padding: 1px;
}

.score-tooltip {
    margin-left: 3em;
}

.score-tooltip-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 5px;
}

.score-tooltip-table td {
    border: 1px solid white;
}

.score-tooltip-table tr td:nth-child(odd) {
    text-align: left;
    padding-left: 2px;
}

.score-tooltip-table tr td:nth-child(even) {
    text-align: right;
    padding-right: 2px;
}

.speed-loading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    background-color: white;
}

#talk-listen-chart{
    height: 228px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multi_delete_extension {
    margin-bottom: 0.5em !important;
}

.multi_delete_extension * {
    float: none !important;
}

.custom-create-option {
    padding: 10px;
    cursor: pointer;
    background-color: #FF8787;
}

.custom-create-option-regexp {
    padding: 10px;
    cursor: pointer;
    background-color: #F5F5DC;
}

.dropdown-indicator-container .control-button {
    margin: 0px 2px 0px 2px;
    background: #808080;
    color: white;
}

.call-compare-average-value {
    background-color: white;
    height: 170px;
    display: flex;
    align-items: center;
}

.common-line-chart-data-label-blue {
    color: #0080FF !important;
    font-weight: bold;
}

.common-line-chart-data-label-orange {
    color: #FFB366 !important;
    font-weight: bold;
}

.keyword-col {
    width: 26.33%;
    padding-right: 0;
}

.keyword-term-col {
    width: 15.33%;
}

.search-button-col {
    width: 3.5%
}

.search-button-col button {
    height: 38px;
}

.clear-button-col {
    width: 4.85%
}

.clear-button-col button {
    color: white;
    background-color: gray;
    border-color: rgb(110, 110, 110);
    height: 38px;
}

.search-count {
    width: fit-content !important;
    padding-top: 7px;
}

.template-btn {
    height: 38px;

}

.call-analysis-dropdown-menu {
    width: 160%;
    text-align: center;
}

.call-analysis-dropdown-menu button {
    margin: 0px 10px 10px 10px !important;
}

.width-auto {
    width: auto;
}

.download-file-status {
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 40%;
}

.creating {
    background-color: #CCFF99;
}

.created {
    background-color: #FFCC99;
}

.failed {
    background-color: #E6E6E6;
}

.notification-overlay {
    width: 386px !important;
}

.call-result-chart {
    background-color: white;
    border: 2px solid #E6E6E6;
    border-radius: 4px;
    padding: 10px;
    height: 600px;
}

.call-result-chart-header {
    border-bottom: 1px solid #E6E6E6;
    margin-bottom: 10px;
    padding-bottom: 5px;
    align-items: center;
    display: flex;
}

.call-result-chart-table-wrapper {
    overflow-x: auto;
    height: 92%;
}

.call-result-chart-table tr {
    cursor: pointer;
}

.call-result-chart-table td {
    border: none !important;
}

.default-tr {
    cursor: default !important;
}

.default-tr:hover {
    background: unset !important;
}

.info-hidden {
    visibility: hidden;
}

@media screen and (min-width: 1200px) {
    .id-search {
        width: 5% !important;
    }
}

.tag-color-style {
    padding: 0;
    margin-left: -12px;
}

.template-dropdown .dropdown,
.template-dropdown .dropdown .btn,
.template-dropdown .dropdown .dropdown-menu {
    width: 100% !important;
}

.template-dropdown .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.template-dropdown .dropdown-toggle .caret {
    margin-left: auto;
}

.template-dropdown .dropdown-toggle .btn-text {
    margin-right: auto;
}

.dropdown-menu:hover, .dropdown-menu:focus{
    background-color: white !important;
}

.call-result-rank-select-form {
    width: 90% !important;
    margin-left: 1.5em;
}

.background-white {
    background-color: white;
}

.display-block {
    display: block;
}

.width-14em {
    width: 14em;
}

.static-form {
    padding-bottom: 20px !important;
}
